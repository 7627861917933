import React from 'react';
import styles from './styles.module.css';

const Footer = () => {
  const date = new Date();

  return (
    <footer id="Footer" className={styles.container}>
      <div className={styles.inner}>
        <h2>CONTÁCTENOS</h2>
        <div className={styles.info}>
          <div className={styles.infoLeft}>
            <div className={styles.detail}><span className={styles.label}>Teléfono:</span><a href="tel:+56993449902">+56993449902</a></div>
            <div className={styles.detail}><span className={styles.label}>Email:</span><a href="mailto:contact@bas-ai.com">contact@bas-ai.com</a></div>
          </div>
          <div className={styles.infoRight}>
            <div className={styles.detail}><span className={styles.label}>Dirección:</span>Cirujano Guzmán N°70 local 1507<br/>Providencia<br/>Santiago<br/>Chile</div>
          </div>
        </div>
      <span className={styles.copyright}>{`©${date.getFullYear()} BAS-AI S.P.A`}</span>
      </div>
    </footer>
  );
}

export default Footer;
