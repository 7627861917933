import React, { useEffect, useState } from "react";
import classnames from 'classnames';
import { Squash as Hamburger } from 'hamburger-react';
import Logo from 'components/logo';
import styles from './styles.module.css';

const Header = () => {
  const [ hasScrolled, setHasScrolled ] = useState(false);
  const [ hamburgerOpen, setHamburgerOpen ] = useState(false);
  const containerClass = classnames(styles.container, { [styles.containerScrolled]: hasScrolled });
  const navClass = classnames(styles.nav, { [styles.open]: hamburgerOpen});
  const hamburgerContainerClass = classnames(styles.hamburgerContainer, { [styles.open]: hamburgerOpen });

  useEffect(() => {
    const listener = () => {
      if (!hasScrolled && window.scrollY > 0) {
        setHasScrolled(true);
      } else if (hasScrolled && window.scrollY === 0) {
        setHasScrolled(false);
      }
    }
    document.addEventListener('scroll', listener);
    return () => {
      document.removeEventListener('scroll', listener);
    }
  }, [hasScrolled]);

  return (
    <header className={containerClass}>
      <div className={styles.logoContainer}><Logo /></div>
      <nav className={navClass}>
        <span><a href={'#Nosotros'}>Nosotros</a></span>
        <span><a href={`#${encodeURI('Nuestros Valores')}`}>Nuestros Valores</a></span>
        <span><a href={`#${encodeURI('Cómo Funciona')}`}>Cómo Funciona</a></span>
        <span><a href={'#Beneficios'}>Beneficios</a></span>
        <span><a href={`#Footer`}>Contacto</a></span>
      </nav>
      <div className={styles.loginContainer}><a href='https://bas-ai.web.app/'><button className={styles.loginButton}>Login</button></a></div>
      
      <div className={hamburgerContainerClass}>
        <Hamburger toggled={hamburgerOpen} toggle={setHamburgerOpen} color={'white'} size={25} />
      </div>
    </header>
  )
}

export default Header
