import React from "react";
import PropTypes from 'prop-types';
import styles from './styles.module.css';
import logo from 'images/logo.png';

const Logo = ({ size }) => (
  <div className={styles.logo}>
    <img src={logo} alt="Logo"></img>
  </div>
);

Logo.propTypes = {
  size: PropTypes.number,
};

Logo.defaultProps = {
  size: 40,
};

export default Logo
